<template>
  <v-col cols="12" sm="8" class="d-flex">
    <v-card
      @click="$parent.idActiveType = item.id"
      v-for="item in items"
      :key="item.id"
      :class="`mx-auto type-offer` + ($parent.idActiveType === item.id ? ' active-type' : '')"
      min-width="150"
      :ripple="false"
      :disabled="disabled(item.id)"
    >
      <v-card-text class="text-center">
        <i :class="item.icon"></i>
        <h4>{{ item.name }}</h4>
      </v-card-text>
    </v-card>
    <!-- <v-card class="mx-auto" min-width="150">
      <v-card-text class="text-center">
        <i class="mdi mdi-cart-plus mdi-24px"></i>
        <h4>In-cart</h4>
      </v-card-text>
    </v-card>
    <v-card class="mx-auto" min-width="150">
      <v-card-text class="text-center">
        <i class="mdi mdi-sale mdi-24px"></i>
        <h4>Post-purchase</h4>
      </v-card-text>
    </v-card> -->
  </v-col>
</template>

<script>
export default {
  props: {
    screenType: {
      type: String,
      default: 'create',
    },
  },
  data() {
    return {
      items: [
        {
          id: 1,
          name: 'Pre-purchase',
          icon: 'mdi mdi-tag-plus mdi-24px',
        },
        {
          id: 2,
          name: 'In-cart',
          icon: 'mdi mdi-cart-plus mdi-24px',
        },
        {
          id: 3,
          name: 'Post-purchase',
          icon: 'mdi mdi-sale mdi-24px',
        },
      ],
    };
  },
  methods: {
    disabled: function(currActiveId) {
      return this.screenType == 'update' && this.$parent.idActiveType != currActiveId;
    },
  },
};
</script>

<style lang="scss" scoped>
.type-offer {
  border-radius: 10px;
}
.active-type {
  i {
    color: var(--v-primary-base);
  }
  border: 1px solid var(--v-primary-base);
}
</style>
